// TeamData.js

const teamData = [
    {
      name: 'Mareedu Lokesh',
      rollNumber: '20VV1A1235',
      year: '2023-2024',
    },
    
    {
        name: 'Paleti Sowmya',
        rollNumber: '20VV1A1244',
        year: '2023-2024',
    },
    
     
      {
        name: 'Rangala Dilleswara Rao',
        rollNumber: '20VV1A1253',
        year: '2023-2024',
      },
     
      {
        name: 'Tammuluri Lokesh',
        rollNumber: '20VV1A1258',
        year: '2023-2024',
      },
     
      



  ];
  
  export default teamData;
  
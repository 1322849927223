// TeamData.js

const teamData = [
    {
      name: 'Gattu Durga Bhavani',
      rollNumber: '20VV1A1216',
      year: '2023-2024',
    },
    
    {
        name: 'Malla Naga Venkata Prasanth Naidu',
        rollNumber: '20VV1A1233',
        year: '2023-2024',
    },
    
     
      {
        name: 'Nulu Prameela ',
        rollNumber: '20VV1A1243',
        year: '2023-2024',
      },
     
      {
        name: 'Robba Sangeetha',
        rollNumber: '20VV1A1255',
        year: '2023-2024',
      },
     
      



  ];
  
  export default teamData;
  
// TeamData.js

const teamData = [
    {
      name: 'Haneesh Bandaru',
      rollNumber: '20VV1A1220',
      year: '2023-2024',
    },
    
    {
        name: 'Thiruvayapeta Srimanth Kumar',
        rollNumber: '20VV1A1259',
        year: '2023-2024',
    },
    
     
      {
        name: '	Dannana Sai Ajith Kumar',
        rollNumber: '21VV5A1268',
        year: '2023-2024',
      },
     
    
     
      



  ];
  
  export default teamData;
  
// TeamData.js

const teamData = [
    {
      name: 'Karupati Vivekananda',
      rollNumber: '20VV1A1227',
      year: '2023-2024',
    },
    
    {
        name: 'Mudunuru Amrutha',
        rollNumber: '20VV1A1239',
        year: '2023-2024',
    },
    
     
      {
        name: 'Thiruvayapeta Srimanth Kumar',
        rollNumber: '20VV1A1259',
        year: '2023-2024',
      },
     
      {
        name: 'Golukonda Anusha',
        rollNumber: '21VV5A1269',
        year: '2023-2024',
      },
     
      



  ];
  
  export default teamData;
  